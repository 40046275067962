import { FC } from "react";
import { Player } from "../../models/player";
import { isCurrentlyOnFire } from "../../utils/scoreUtils";

interface FireIconProps {
    roundNumber: number;
    player: Player;
}

export const FireIcon: FC<FireIconProps> = (props) => {
    const isOnFire = () => {
        if (props.roundNumber === 0) {
            return false;
        }

        return isCurrentlyOnFire(props.player.rounds.slice(0, props.roundNumber));
    };

    if (!isOnFire()) {
        return null;
    }

    return <span className="absolute -right-3 text-2xl">🔥</span>;
};
