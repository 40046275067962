import { FC } from "react";
import { Player } from "../../models/player";
import { calculateTotalScore, isCurrentlyOnFire } from "../../utils/scoreUtils";
import Avatar from "../common/Avatar";

interface LeaderBoardEntryProps {
    player: Player;
    ranking: number;
}

const LeaderBoardEntry: FC<LeaderBoardEntryProps> = (props) => {
    const finalScore = calculateTotalScore(props.player.rounds);

    const getRankingAward = () => {
        if (props.ranking === 1) {
            return "🥇";
        } else if (props.ranking === 2) {
            return "🥈";
        } else if (props.ranking === 3) {
            return "🥉";
        }
    };

    return (
        <div className="flex justify-between items-center dark:text-stone-200">
            <div className="flex items-center gap-4">
                <span className="text-2xl w-4 text-center">{props.ranking}</span>
                <div className="h-16 aspect-square rounded overflow-hidden shadow-md">
                    <Avatar name={props.player.name} />
                </div>
                <span>{props.player.name}</span>
            </div>
            <div className="flex items-center gap-4">
                <span>
                    {isCurrentlyOnFire(props.player.rounds) && "🔥"}
                    {getRankingAward()}
                </span>
                <span className="font-bold text-xl">{finalScore}</span>
            </div>
        </div>
    );
};

export default LeaderBoardEntry;
