import { FC } from "react";
import { Player } from "../../models/player";
import LeaderBoardEntry from "../summary/LeaderBoardEntry";
import { orderByRank } from "../../utils/scoreUtils";
import { PageHeader } from "../common/PageHeader";
import { DockLayout } from "../layouts/DockLayout";
import { Dock } from "../common/Dock";
import { Button } from "../common/Button";
import { RefreshCw } from "react-feather";

interface SummaryViewProps {
    players: Player[];
    onNewGame: () => void;
}

export const SummaryView: FC<SummaryViewProps> = (props) => {
    const rankedPlayers = orderByRank(props.players);

    const handleNewGame = () => {
        props.onNewGame();
    };

    const dock = (
        <Dock>
            <Button className="w-full" onClick={handleNewGame}>
                New game &nbsp;
                <RefreshCw size="1rem" />
            </Button>
        </Dock>
    );

    return (
        <DockLayout dock={dock}>
            <PageHeader title="Final Scores"></PageHeader>

            <div className="flex flex-col gap-2">
                {rankedPlayers.map((ranking, index) => {
                    return <LeaderBoardEntry key={index} player={ranking.player} ranking={ranking.rank} />;
                })}
            </div>
        </DockLayout>
    );
};
