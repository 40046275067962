import React, { useEffect } from "react";
import { FC } from "react";
import { parseNumberOrDefault } from "../../utils/numericUtils";

interface PlayerInputProps {
    value: number;
    tabIndex?: number;
    onValidValueChange: (value: number) => void;
    onChange: (value: string) => void;
}

const validateInput = (value: string) => {
    return value !== "" && parseInt(value) >= 0;
};

export const PlayerInput: FC<PlayerInputProps> = (props) => {
    const [value, setValue] = React.useState<string>(props.value.toString());
    const [isValid, setIsValid] = React.useState(validateInput(props.value.toString()));

    const { value: propsValue } = props;
    useEffect(() => {
        setValue(propsValue.toString());
        setIsValid(validateInput(propsValue.toString()));
    }, [propsValue]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(event.target.value);
        props.onChange(event.target.value);

        if (event.target.value !== "") {
            props.onValidValueChange(parseNumberOrDefault(event.target.value));
        }
    };

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value === "") {
            setValue(props.value.toString());
        } else {
            setIsValid(validateInput(event.target.value));
        }
    };

    return (
        <input
            className={`text-4xl w-8 text-center border-4 rounded-lg dark:bg-gray-700 dark:text-stone-200 ${
                !isValid ? "border-red-500" : "border-transparent"
            }`}
            type="number"
            value={value}
            onFocus={() => setValue("")}
            onChange={handleChange}
            onBlur={handleBlur}
            pattern="[0-9]*"
            tabIndex={props.tabIndex}
        />
    );
};
