import { FC } from "react";
import BoringAvatar from "boring-avatars";
import { randomBackgroundColor } from "../../utils/colorUtils";

interface AvatarProps {
    name: string;
}

const Avatar: FC<AvatarProps> = (props) => {
    const color = randomBackgroundColor(props.name);

    return <BoringAvatar square name={props.name} variant="beam" size="100%" colors={[color]} />;
};

export default Avatar;
