import { FC } from "react";
import { Player } from "../../models/player";
import { getRankings } from "../../utils/scoreUtils";

interface RankingIconProps {
    roundNumber: number;
    playerIndex: number;
    players: Player[];
}

export const RankingIcon: FC<RankingIconProps> = (props) => {
    const rankings =
        props.roundNumber === 0
            ? new Array(props.players.length).fill(-1)
            : getRankings(
                  props.players.map((player) => {
                      const newPlayer = { ...player };
                      newPlayer.rounds = player.rounds.slice(0, props.roundNumber);

                      return newPlayer;
                  }),
              );

    const getRankingIcon = (rank: number) => {
        switch (rank) {
            case 1:
                return "🥇";
            case 2:
                return "🥈";
            case 3:
                return "🥉";
            default:
                if (rank === props.players.length) {
                    return "💩";
                }
                else {
                    return "";
                }
        }
    };

    return <span className="absolute -left-4 text-2xl">{getRankingIcon(rankings[props.playerIndex])}</span>;
};
