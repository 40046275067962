import { FC, ReactNode } from "react";

interface PageHeaderProps {
    title: ReactNode;
    subtitle?: ReactNode;
}

export const PageHeader: FC<PageHeaderProps> = (props) => {
    return (
        <header className="my-4">
            <h1 className="text-4xl text-center dark:text-stone-200">{props.title}</h1>
            <h2 className="text-center dark:text-stone-200">{props.subtitle}</h2>
        </header>
    );
};
