import { FC, useState } from "react";
import { Play, Settings as SettingsIcon } from "react-feather";
import { Player } from "../../models/player";
import { Settings } from "../../models/settings";
import { hasEnoughPlayersToStart } from "../../utils/gameUtils";
import { Button } from "../common/Button";
import { Dock } from "../common/Dock";
import { DockLayout } from "../layouts/DockLayout";
import { PlayerManager } from "../playerManager/PlayerManager";
import { SettingsPanel } from "../playerManager/SettingsPanel";

interface PlayerManagerViewProps {
    players: Player[];
    settings: Settings;
    onPlayerChange: (playerIndex: number, player: Player) => void;
    onPlayerCreate: (player: Player) => void;
    onPlayerDelete: (playerIndex: number) => void;
    onSettingsChange: (settings: Settings) => void;
    onStart: () => void;
}

// TODO: Rename to SetupView
export const PlayerManagerView: FC<PlayerManagerViewProps> = (props) => {
    const [isSettingsPanelVisible, setIsSettingsPanelVisible] = useState(false);

    const handleSettingsPanelClose = () => {
        setIsSettingsPanelVisible(false);
    }

    const dock = (
        <Dock show={hasEnoughPlayersToStart(props.players)}>
            <Button className="w-full" onClick={props.onStart}>
                Start &nbsp;
                <Play size="1rem" />
            </Button>
        </Dock>
    );

    return (
        <DockLayout dock={dock}>
            <button onClick={() => setIsSettingsPanelVisible(true)} className="absolute right-7 top-9"><SettingsIcon className="dark:text-white" /></button>
            <SettingsPanel isVisible={isSettingsPanelVisible} value={props.settings} onChange={props.onSettingsChange} onClose={handleSettingsPanelClose} />

            <PlayerManager
                players={props.players}
                onPlayerChange={props.onPlayerChange}
                onPlayerCreate={props.onPlayerCreate}
                onPlayerDelete={props.onPlayerDelete}
            />
        </DockLayout>
    );
};
