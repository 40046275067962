import { EffectCallback, useEffect, useRef } from "react";

export const useMountEffect = (fn: EffectCallback) => {
    const hasBeenCalled = useRef(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!hasBeenCalled.current) {
            hasBeenCalled.current = true;
            fn();
        }
    }, []);
};
