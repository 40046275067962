import { FC } from "react";

interface PlayerNameAndScoreProps {
    playerName: string;
    totalScore: number;
    roundScore: number;
    gotBonus: boolean;
}

export const PlayerNameAndScore: FC<PlayerNameAndScoreProps> = (props) => {
    const getRoundScoreColor = () => {
        if (props.gotBonus) {
            return "text-green-500";
        } else if (props.roundScore < 0) {
            return "text-red-500";
        } else {
            return "text-gray-500 dark:text-stone-400";
        }
    };

    const roundScoreDisplay = props.roundScore >= 0 ? `+${props.roundScore}` : props.roundScore;

    return (
        <div className="text-sm text-center dark:text-stone-200">
            <p>{props.playerName}</p>
            <p>
                <span>{props.totalScore}</span>
                &nbsp;
                <span className={getRoundScoreColor()}>({roundScoreDisplay})</span>
            </p>
        </div>
    );
};
