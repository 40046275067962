import { FC, PropsWithChildren, ReactNode } from "react";

interface DockLayoutProps extends PropsWithChildren {
    dock: ReactNode;
}

// TODO: Add settings panel
export const DockLayout: FC<DockLayoutProps> = ({ dock, children }) => {
    return (
        <div className="m-6">
            <main>{children}</main>
            <aside>{dock}</aside>
        </div>
    );
};
