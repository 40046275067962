import { FC } from "react";
import { ORIGINAL_DEALER_INDEX } from "../../constants/gameConstants";
import { Player } from "../../models/player";
import { getDealer } from "../../utils/gameUtils";

interface DealerIconProps {
    roundNumber: number;
    players: Player[];
    playerIndex: number;
}

export const DealerIcon: FC<DealerIconProps> = (props) => {
    var dealer = getDealer(props.players, ORIGINAL_DEALER_INDEX, props.roundNumber);

    if (dealer === props.playerIndex) {
        return <span className="absolute -top-3 left-1/2 -translate-x-1/2 text-2xl">🎲</span>;
    } else {
        return null;
    }
};
