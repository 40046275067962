import React from "react";
import { FC } from "react";
import { Player } from "../../models/player";
import { calculateRoundScore, calculateTotalScore, gotBonus } from "../../utils/scoreUtils";
import { DealerIcon } from "./DealerIcon";
import { FireIcon } from "./FireIcon";
import { PlayerInput } from "./PlayerInput";
import { PlayerNameAndScore } from "./PlayerNameAndScore";
import { RankingIcon } from "./RankingIcon";

interface GameRoundInputsProps {
    players: Player[];
    currentRoundNumber: number;
    roundNumber: number;
    numberOfCards: number;
    onGuessChange: (playerIndex: number, guess: number) => void;
    onActualChange: (playerIndex: number, actual: number) => void;
    onIsValidInputChange: (isValidInput: boolean) => void;
}

export const GameRoundInputs: FC<GameRoundInputsProps> = (props) => {
    const [isValidGuessInput, setIsValidGuessInput] = React.useState(props.players.map(() => true));
    const [isValidActualInput, setIsValidActualInput] = React.useState(props.players.map(() => true));

    const { onIsValidInputChange } = props;
    React.useEffect(() => {
        const areAllGuessInputsValid = isValidGuessInput.every((isValid) => isValid);
        const areAllActualInputsValid = isValidActualInput.every((isValid) => isValid);

        onIsValidInputChange(areAllGuessInputsValid && areAllActualInputsValid);
    }, [isValidGuessInput, isValidActualInput, onIsValidInputChange]);

    const playerInputs = props.players.map((player) => player.rounds[props.roundNumber]);

    const handleValidGuessChange = (playerIndex: number, guess: number) => {
        props.onGuessChange(playerIndex, guess);
    };

    const handleValidActualChange = (playerIndex: number, actual: number) => {
        props.onActualChange(playerIndex, actual);
    };

    const handleOnGuessChange = (playerIndex: number, value: string) => {
        if (value === "" || parseInt(value) < 0) {
            setIsValidGuessInput(isValidGuessInput.map((x, index) => (index === playerIndex ? false : x)));
        } else {
            setIsValidGuessInput(isValidGuessInput.map((x, index) => (index === playerIndex ? true : x)));
        }
    };

    const handleOnActualChange = (playerIndex: number, value: string) => {
        if (value === "" || parseInt(value) < 0) {
            setIsValidActualInput(isValidActualInput.map((x, index) => (index === playerIndex ? false : x)));
        } else {
            setIsValidActualInput(isValidActualInput.map((x, index) => (index === playerIndex ? true : x)));
        }
    };

    const currentTotalScores = props.players.map((player) => {
        if (props.roundNumber === 0) {
            return 0;
        }

        return calculateTotalScore(player.rounds.slice(0, props.roundNumber));
    });

    const currentRoundScores = props.players.map((player) => {
        const round = player.rounds[props.roundNumber];
        return round ? calculateRoundScore(round.guess, round.actual) : 0;
    });

    return (
        <div>
            <div className="uppercase font-bold flex justify-between mb-1 dark:text-stone-200">
                <span>Guess</span>
                <span>Actual</span>
            </div>
            {props.players.map((player, index) => (
                <div
                    className="bg-white dark:bg-gray-700 rounded-lg p-5 mb-2 shadow-sm flex justify-between items-center relative"
                    key={index}
                >
                    <PlayerInput
                        value={playerInputs[index]?.guess ?? 0}
                        onValidValueChange={(value) => handleValidGuessChange(index, value)}
                        onChange={(value) => handleOnGuessChange(index, value)}
                        tabIndex={props.currentRoundNumber === props.roundNumber ? index + 1 : -1}
                    />
                    <PlayerNameAndScore
                        playerName={player.name}
                        roundScore={currentRoundScores[index]}
                        totalScore={currentTotalScores[index]}
                        gotBonus={gotBonus(playerInputs[index]?.guess ?? 0, playerInputs[index]?.actual ?? 0)}
                    />
                    <PlayerInput
                        value={playerInputs[index]?.actual ?? 0}
                        onValidValueChange={(value) => handleValidActualChange(index, value)}
                        onChange={(value) => handleOnActualChange(index, value)}
                        tabIndex={
                            props.currentRoundNumber === props.roundNumber ? index + 1 + props.players.length : -1
                        }
                    />

                    <RankingIcon players={props.players} roundNumber={props.roundNumber} playerIndex={index} />
                    <FireIcon player={player} roundNumber={props.roundNumber} />
                    <DealerIcon players={props.players} roundNumber={props.roundNumber} playerIndex={index} />
                </div>
            ))}
        </div>
    );
};
