import { FC } from "react";
import { Player } from "../../models/player";
import { PageHeader } from "../common/PageHeader";
import { AddTile } from "./AddTile";
import { PlayerTile } from "./PlayerTile";

interface PlayerManagerProps {
    players: Player[];
    onPlayerChange: (playerIndex: number, player: Player) => void;
    onPlayerCreate: (player: Player) => void;
    onPlayerDelete: (playerIndex: number) => void;
}

export const PlayerManager: FC<PlayerManagerProps> = (props) => {
    const handlePlayerNameChange = (playerIndex: number) => {
        const newPlayerName = prompt("Enter player name:", props.players[playerIndex].name)?.trim();
        const newPlayer = { ...props.players[playerIndex] };

        if (newPlayerName) {
            newPlayer.name = newPlayerName;
            props.onPlayerChange(playerIndex, newPlayer);
        } else if (newPlayerName === "") {
            props.onPlayerDelete(playerIndex);
        }
    };

    const handleAddPlayerClick = () => {
        const newPlayerName = prompt("Enter player name:")?.trim();
        if (newPlayerName) {
            props.onPlayerCreate({ name: newPlayerName, rounds: [] });
        }
    };

    const handlePlayerDelete = (playerIndex: number) => {
        const confirmation = window.confirm(`You are about to remove ${props.players[playerIndex].name}`);
        if (confirmation) {
            props.onPlayerDelete(playerIndex);
        }
    };

    const numberOfPlayers = props.players.length;

    return (
        <div>
            <PageHeader title="Game Setup" subtitle={`${numberOfPlayers} players`}></PageHeader>

            {/* TODO: Enable click and drag to rearrange */}
            <div className="grid grid-cols-2 gap-6 mt-8">
                {props.players.map((player, index) => (
                    <PlayerTile
                        key={index}
                        playerName={player.name}
                        onEditClick={() => handlePlayerNameChange(index)}
                        onDelete={() => handlePlayerDelete(index)}
                    />
                ))}

                <AddTile onClick={handleAddPlayerClick} />
            </div>
        </div>
    );
};
