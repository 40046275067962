import { FC } from "react";

interface BaseSettingsPanelItemProps {
    label: string;
    name: string;
    inputElement: JSX.Element;
}

export interface GenericSettingsPanelItemProps<T> {
    label: string;
    name: string;
    value: T;
    onChange: (value: T) => void;
    minimum?: T;
    maximum?: T;
}

export const BaseSettingsPanelItem: FC<BaseSettingsPanelItemProps> = (props) => {
    return (
        <>
            <label htmlFor={props.name}>{props.label}</label>
            <>{props.inputElement}</>
        </>
    );
}