import { GameView } from "./components/views/GameView";
import { PlayerManagerView } from "./components/views/PlayerManagerView";
import { SummaryView } from "./components/views/SummaryView";
import { Pages as Page } from "./constants/page";
import { defaultSettings } from "./constants/settings";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { Player } from "./models/player";
import { Settings } from "./models/settings";

// TODO: Contain entire application within a box that is the same size as a phone when on a large display like desktop
function App() {
    const [players, setPlayers] = useLocalStorage<Player[]>("players", []);
    const [settings, setSettings] = useLocalStorage<Settings>("settings", defaultSettings);
    const [page, setPage] = useLocalStorage("page", Page.PlayerManager);

    const handlePlayerChange = (playerIndex: number, player: Player) => {
        const newPlayers = [...players];
        newPlayers[playerIndex] = player;
        setPlayers(newPlayers);
    };

    const handlePlayerCreate = (newPlayer: Player) => {
        setPlayers([...players, newPlayer]);
    };

    const handlePlayerDelete = (playerIndex: number) => {
        const newPlayers = [...players];
        newPlayers.splice(playerIndex, 1);
        setPlayers(newPlayers);
    };

    const handleSettingsChange = (settings: Settings) => {
        setSettings(settings);
    }

    const handleStart = () => {
        setPage(Page.Game);
    };

    const handleEnd = () => {
        setPage(Page.Summary);
    };

    const handleNewGame = () => {
        setPlayers(
            players.map((player) => {
                const newPlayer = { ...player };
                newPlayer.rounds = [];
                return newPlayer;
            }),
        );
        setPage(Page.PlayerManager);
    };

    switch (page) {
        case Page.PlayerManager:
            return (
                <PlayerManagerView
                    players={players}
                    settings={settings}
                    onPlayerChange={handlePlayerChange}
                    onPlayerCreate={handlePlayerCreate}
                    onPlayerDelete={handlePlayerDelete}
                    onSettingsChange={handleSettingsChange}
                    onStart={handleStart}
                />
            );
        case Page.Game:
            return <GameView players={players} settings={settings} onPlayerChange={handlePlayerChange} onEnd={handleEnd} />;
        case Page.Summary:
            return <SummaryView players={players} onNewGame={handleNewGame} />;
        default:
            return <div className="text-red-500 font-extrabold">Error resolving page!</div>;
    }
}

export default App;
