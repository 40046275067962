import { FC, PropsWithChildren } from "react";
import { animated as a, useTransition } from "react-spring";

interface DockProps extends PropsWithChildren {
    show?: boolean;
}

export const Dock: FC<DockProps> = ({ children, show = true }) => {
    const transition = useTransition(show, {
        from: { y: 150 },
        enter: { y: 0 },
        leave: { y: 150 },
    });

    return (
        <div style={{ height: "calc(72px + 24px)" }} className={!show ? "pointer-events-none" : ""}>
            {/* ^ HACK: Equal to the dock height plus the margin-top. Reserves space to not overlap items when scrolled to bottom of page */}
            {transition(
                (styles, item) =>
                    item && (
                        <a.div
                            style={styles}
                            className="text-sm fixed z-40 bottom-0 left-0 right-0 m-6 p-2 bg-gray-900 dark:bg-gray-800 text-white rounded-full shadow-lg"
                        >
                            {children}
                        </a.div>
                    ),
            )}
        </div>
    );
};
