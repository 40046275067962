import { FC } from "react";
import { Settings } from "../../models/settings";
import { Panel } from "../common/Panel";
import { NumberSettingsPanelItem } from "./SettingsPanelItems/NumberSettingsPanelItem";

interface SettingsPanelProps {
    isVisible: boolean;
    value: Settings;
    onChange: (value: Settings) => void;
    onClose: () => void;
}

export const SettingsPanel: FC<SettingsPanelProps> = (props) => {
    const handleMaxNumberOfStartingCardsChange = (value: number) => {
        props.onChange({ ...props.value, maximumNumberOfCards: value });
    }

    return (
        <Panel title="Settings" isVisible={props.isVisible} onClose={props.onClose}>
            <div className="grid grid-cols-[max-content_1fr] gap-4">
                <NumberSettingsPanelItem name="max-number-of-starting-cards" label="Number of starting cards" minimum={1} value={props.value.maximumNumberOfCards} onChange={handleMaxNumberOfStartingCardsChange} />
            </div>
        </Panel>
    );
}