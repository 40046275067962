import { ChangeEventHandler, FC } from "react";
import { BaseSettingsPanelItem, GenericSettingsPanelItemProps } from "./BaseSettingsPanelItem";

interface NumberSettingsPanelItemProps extends GenericSettingsPanelItemProps<number> {
}

export const NumberSettingsPanelItem: FC<NumberSettingsPanelItemProps> = (props) => {

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        let value = parseInt(e.currentTarget.value);

        if (isNaN(value)) {
            value = 0;
        }

        props.onChange(value);
    }

    const inputElement = <input type="number" id={props.name} value={props.value} onChange={handleChange} min={props.minimum} max={props.maximum} className="text-center w-full font-bold" />;

    return (
        <BaseSettingsPanelItem name={props.name} label={props.label} inputElement={inputElement} />
    )
}