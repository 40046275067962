const backgroundColors = [
    "#ef4444",
    "#3b82f6",
    "#f59e0b",
    "#eab308",
    "#f97316",
    "#a855f7",
    "#6366f1",
    "#ec4899",
    "#14b8a6",
];

const createHash = (input: string) => {
    return input.split("").reduce((acc, curr) => acc + curr.charCodeAt(0), 0);
};

export const randomBackgroundColor = (input: string) => {
    const hash = createHash(input);
    return backgroundColors[hash % backgroundColors.length];
};
