import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useLocalStorage = <TValue>(
    key: string,
    initialValue: TValue,
): [TValue, Dispatch<SetStateAction<TValue>>] => {
    const [value, setValue] = useState<TValue>(() => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [
        value,
        (v) => {
            localStorage.setItem(key, JSON.stringify(v));
            setValue(v);
        },
    ];
};
