import { MIN_REQUIRED_PLAYER_COUNT } from "../constants/gameConstants";
import { Player } from "../models/player";

export const hasEnoughPlayersToStart = (players: Player[]) => {
    return players.length >= MIN_REQUIRED_PLAYER_COUNT;
};

export const getDealer = (players: Player[], originalDealer: number, currentRound: number) => {
    return (originalDealer + currentRound) % players.length;
};

export const getNumberOfCards = (currentRound: number, totalRounds: number) => {
    const runLength = Math.floor(totalRounds / 2);
    return Math.abs(currentRound - runLength) + 1;
};

export const isBidValid = (bid: number, currentRound: number, totalRounds: number) => {
    const numberOfCards = getNumberOfCards(currentRound, totalRounds);
    return numberOfCards !== bid;
};

export const getTotalNumberOfRounds = (maximumNumberOfCards: number) => {
    return maximumNumberOfCards * 2 - 1;
}
