import { FC, MouseEventHandler, PropsWithChildren } from "react";
import { Trash2 } from "react-feather";
import Avatar from "../common/Avatar";
import { PlayerManagerTile } from "./PlayerManagerTile";
import { PlayerTileLabel } from "./PlayerTileLabel";

interface PlayerTileProps extends PropsWithChildren {
    playerName: string;
    onEditClick: MouseEventHandler<HTMLInputElement>;
    onDelete: () => void;
}

export const PlayerTile: FC<PlayerTileProps> = (props) => {
    const playerTileLabel = <PlayerTileLabel playerName={props.playerName} onClick={props.onEditClick} />;

    return (
        <PlayerManagerTile label={playerTileLabel}>
            <div className="relative drop-shadow-lg">
                <div className="overflow-hidden rounded-lg">
                    <Avatar name={props.playerName} />
                </div>
                <div
                    className="bg-white dark:bg-gray-600 absolute right-0 top-0 translate-x-1/2 -translate-y-1/2 rounded-full p-2"
                    onClick={props.onDelete}
                >
                    <Trash2 color="#ef4444" />
                </div>
            </div>
        </PlayerManagerTile>
    );
};
