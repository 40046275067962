import { FC, PropsWithChildren, ReactNode } from "react";

interface PlayerManagerTileProps extends PropsWithChildren {
    label?: ReactNode;
}

export const PlayerManagerTile: FC<PlayerManagerTileProps> = (props) => {
    return (
        <div>
            <div className={`aspect-square flex justify-center items-center text-xl font-bold mb-2`}>
                {props.children}
            </div>
            {props.label}
        </div>
    );
};
