import { ButtonHTMLAttributes, FC } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    buttonType?: "primary" | "secondary";
}

const staticStyles = "p-4 rounded-full flex items-center transition-colors duration-200 justify-center";
const primaryStyles = { default: "bg-green-500", disabled: "bg-gray-400" };
const secondaryStyles = { default: "text-white", disabled: "text-gray-400" };

export const Button: FC<ButtonProps> = ({ buttonType = "primary", className, children, ...props }) => {
    const getDynamicStyles = () => {
        let dynamicStyles = "";

        switch (buttonType) {
            case "primary":
                dynamicStyles += !props.disabled ? primaryStyles.default : primaryStyles.disabled;
                break;
            case "secondary":
                dynamicStyles += !props.disabled ? secondaryStyles.default : secondaryStyles.disabled;
                break;
        }

        return dynamicStyles;
    };

    const getStyles = () => {
        const dynamicStyles = getDynamicStyles();
        return `${staticStyles} ${dynamicStyles}`;
    };

    return (
        <button className={`${getStyles()} ${className}`} {...props}>
            {children}
        </button>
    );
};
