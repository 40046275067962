import { FC, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useMountEffect } from "../../hooks/useMountEffect";
import { Player } from "../../models/player";
import { Button } from "../common/Button";
import { Dock } from "../common/Dock";
import { Game } from "../game/Game";
import { DockLayout } from "../layouts/DockLayout";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperType from "swiper";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getTotalNumberOfRounds } from "../../utils/gameUtils";
import { Settings } from "../../models/settings";

interface GameViewProps {
    players: Player[];
    settings: Settings;
    onPlayerChange: (playerIndex: number, player: Player) => void;
    onEnd: () => void;
}

export const GameView: FC<GameViewProps> = (props) => {
    const [currentRoundNumber, setCurrentRoundNumber] = useLocalStorage("currentRound", 0);
    const [inputsAreValid, setInputsAreValid] = useState(false);
    const [isBidValid, setIsBidValid] = useState(true);

    const swiperRef = useRef<SwiperType | null>(null);

    const totalRounds = getTotalNumberOfRounds(props.settings.maximumNumberOfCards);

    const handlePreviousRoundButtonClick = () => {
        if (currentRoundNumber > 0) {
            if (swiperRef.current) {
                swiperRef.current.slidePrev(undefined, false);
            }

            setCurrentRoundNumber(currentRoundNumber - 1);
        }
    };

    const handleNextRoundButtonClick = () => {
        if (currentRoundNumber < totalRounds - 1) {
            if (swiperRef.current) {
                swiperRef.current.slideNext(undefined, false);
            }

            setCurrentRoundNumber(currentRoundNumber + 1);
        } else if (currentRoundNumber === totalRounds - 1) {
            setCurrentRoundNumber(0);
            props.onEnd();
        }
    };

    const handleInputValidation = (isValid: boolean) => {
        setInputsAreValid(isValid);
    };

    const handleBidValidation = (roundNumber: number, isValid: boolean) => {
        if (roundNumber === currentRoundNumber) {
            setIsBidValid(isValid);
        }
    };

    const populateRounds = () => {
        if (props.players[0].rounds.length === 0) {
            const newPlayers = props.players.map((player) => {
                const newPlayer = { ...player };
                for (let i = 0; i < totalRounds; i++) {
                    newPlayer.rounds.push({ guess: 0, actual: 0 });
                }
                return newPlayer;
            });

            newPlayers.forEach((player, index) => props.onPlayerChange(index, player));
        }
    };

    useMountEffect(populateRounds);

    const dock = (
        <Dock show={inputsAreValid && isBidValid}>
            <div className="flex justify-between">
                <Button
                    onClick={handlePreviousRoundButtonClick}
                    buttonType="secondary"
                    className={`transition-opacity ${
                        currentRoundNumber === 0 ? "pointer-events-none cursor-default opacity-0" : "opacity-1"
                    }`}
                >
                    <ChevronLeft />
                    Back
                </Button>

                <Button onClick={handleNextRoundButtonClick}>
                    {currentRoundNumber !== totalRounds - 1 ? "Next Round" : "End Game"}
                    <ChevronRight />
                </Button>
            </div>
        </Dock>
    );

    const handleSwiperActiveIndexChange = (swiper: SwiperType) => {
        setCurrentRoundNumber(swiper.activeIndex);
    };

    return (
        <DockLayout dock={dock}>
            <Swiper
                initialSlide={currentRoundNumber}
                onInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                onAfterInit={(swiper) => {
                    swiper.slideTo(currentRoundNumber, undefined, false);
                }}
                spaceBetween={50}
                onActiveIndexChange={handleSwiperActiveIndexChange}
                allowSlideNext={inputsAreValid && isBidValid && currentRoundNumber !== totalRounds - 1}
                allowSlidePrev={inputsAreValid && isBidValid && currentRoundNumber !== 0}
            >
                {Array.from({ length: totalRounds }).map((_, index) => (
                    <SwiperSlide key={index}>
                        <Game
                            players={props.players}
                            roundNumber={index}
                            currentRoundNumber={currentRoundNumber}
                            totalRounds={totalRounds}
                            isBidValid={isBidValid}
                            onPlayerChange={props.onPlayerChange}
                            onInputValidation={handleInputValidation}
                            onBidValidation={(isValid) => handleBidValidation(index, isValid)}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </DockLayout>
    );
};
