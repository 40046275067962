import { CORRECT_GUESS_BONUS } from "../constants/scoreConstants";
import { Player } from "../models/player";

export const gotBonus = (guess: number, actual: number) => {
    return guess === actual;
};

export const calculateRoundScore = (guess: number, actual: number) => {
    let score = 0;

    if (actual < guess) {
        score = actual - guess;
    } else {
        score = actual;
    }

    if (gotBonus(guess, actual)) {
        score += CORRECT_GUESS_BONUS;
    }

    return score;
};

export const calculateTotalScore = (rounds: { guess: number; actual: number }[]) => {
    return rounds.reduce((acc, round) => {
        return acc + calculateRoundScore(round.guess, round.actual);
    }, 0);
};

export const isCurrentlyOnFire = (rounds: { guess: number; actual: number }[]) => {
    return rounds.every((round) => round.guess === round.actual);
};

export const getRankings = (players: Player[]) => {
    const scores = players
        .map((player, index) => ({
            playerIndex: index,
            score: calculateTotalScore(player.rounds),
            rank: -1,
        }))
        .sort((a, b) => b.score - a.score);

    scores.forEach((score, index, array) => {
        if (index === 0 || score.score !== array[index - 1].score) {
            score.rank = index + 1;
        } else {
            score.rank = array[index - 1].rank;
        }
    });

    scores.sort((a, b) => a.playerIndex - b.playerIndex);
    return scores.map(({ rank }) => rank);
};

export const orderByRank = (players: Player[]) => {
    const rankings = getRankings(players);

    return rankings
        .map((rank, index) => ({ rank, playerIndex: index }))
        .sort((a, b) => a.rank - b.rank)
        .map(({ playerIndex, rank }) => ({
            player: players[playerIndex],
            rank,
        }));
};
