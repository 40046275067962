import { FC, MouseEventHandler } from "react";
import { PlusCircle } from "react-feather";
import { PlayerManagerTile } from "./PlayerManagerTile";

interface AddTileProps {
    onClick: MouseEventHandler;
}

export const AddTile: FC<AddTileProps> = (props) => {
    return (
        <PlayerManagerTile>
            <button
                className="bg-gray-200 dark:bg-gray-800 dark:text-stone-200 w-full h-full flex items-center justify-center rounded-lg"
                onClick={props.onClick}
            >
                <PlusCircle />
            </button>
        </PlayerManagerTile>
    );
};
