import { FC, MouseEventHandler } from "react";
import { Edit3 } from "react-feather";

interface PlayerTileLabelProps {
    playerName: string;
    onClick: MouseEventHandler<HTMLInputElement>;
}

export const PlayerTileLabel: FC<PlayerTileLabelProps> = (props) => {
    return (
        <span className="flex items-center justify-center cursor-pointer dark:text-stone-300" onClick={props.onClick}>
            {props.playerName}
            <Edit3 className="h-5" />
        </span>
    );
};
