import { FC, MouseEventHandler, PropsWithChildren } from "react";
import { X } from "react-feather";
import { Portal } from "react-portal";
import { PageHeader } from "./PageHeader";

interface PanelProps extends PropsWithChildren {
    title: string;
    isVisible: boolean;
    onClose: () => void;
}

export const Panel: FC<PanelProps> = (props) => {
    const handleBackdropClick: MouseEventHandler<HTMLDivElement> = (event) => {
        // If the backdrop was clicked, close the panel
        // This prevents the panel from closing when the user clicks an element inside the panel that is not the backdrop
        if (event.target === event.currentTarget) {
            props.onClose();
        }
    }

    return (
        <Portal>
            <div className={`${props.isVisible ? "visible" : "hidden"} bg-black bg-opacity-30 dark:bg-opacity-70 p-6 fixed left-0 top-0 w-full h-full z-50`} onClick={handleBackdropClick}>
                <div className="bg-white dark:bg-gray-900 relative p-6 rounded-lg">
                    <button onClick={props.onClose} className="absolute right-4 top-4 dark:text-stone-200"><X /></button>
                    <PageHeader title={props.title} />
                
                    {props.children}
                </div>
            </div>
        </Portal>
    );
}