import { useEffect, useMemo, useState } from "react";
import { FC } from "react";
import { Player } from "../../models/player";
import * as GameUtils from "../../utils/gameUtils";
import { PageHeader } from "../common/PageHeader";
import { GameRoundInputs } from "./GameRoundInputs";

interface GameProps {
    players: Player[];
    roundNumber: number;
    currentRoundNumber: number;
    totalRounds: number;
    isBidValid: boolean;
    onPlayerChange: (playerIndex: number, player: Player) => void;
    onInputValidation: (areInputsValid: boolean) => void;
    onBidValidation: (isBidValid: boolean) => void;
}

export const Game: FC<GameProps> = ({
    players,
    roundNumber,
    currentRoundNumber,
    totalRounds,
    isBidValid,
    onPlayerChange,
    onInputValidation,
    onBidValidation,
}) => {
    const currentRoundNumberDisplay = useMemo(() => {
        return roundNumber + 1;
    }, [roundNumber]);

    const [bid, setBid] = useState(0);

    const handleGuessChange = (playerIndex: number, guess: number) => {
        const newPlayer = { ...players[playerIndex] };
        newPlayer.rounds[roundNumber].guess = guess;

        onPlayerChange(playerIndex, newPlayer);
    };

    const handleActualChange = (playerIndex: number, actual: number) => {
        const newPlayer = { ...players[playerIndex] };
        newPlayer.rounds[roundNumber].actual = actual;

        onPlayerChange(playerIndex, newPlayer);
    };

    const handleIsValidInputChange = (isValidInput: boolean) => {
        onInputValidation(isValidInput);
    };

    const areRoundsPopulated = () => {
        return players.every((player) => player.rounds.length === totalRounds);
    };

    useEffect(() => {
        if (players.every((player) => player.rounds.length === totalRounds)) {
            setBid(
                players
                    .map((p) => (p.rounds[roundNumber] && p.rounds[roundNumber].guess) ?? 0)
                    .reduce((accu, value) => accu + value, 0),
            );
        } else {
            setBid(0);
        }
    }, [roundNumber, players, totalRounds]);

    useEffect(() => {
        const bidIsValid = GameUtils.isBidValid(bid, roundNumber, totalRounds);
        onBidValidation(bidIsValid);
    });

    return (
        <div>
            <PageHeader
                title={
                    <>
                        Round <span className="font-bold">{currentRoundNumberDisplay}</span> of{" "}
                        <span className="font-bold">{totalRounds}</span>
                    </>
                }
                subtitle={
                    <>
                        <span>{GameUtils.getNumberOfCards(roundNumber, totalRounds)} cards</span>{" "}
                        <span className={!isBidValid ? "text-red-500 font-bold" : ""}>(Bid: {bid})</span>
                    </>
                }
            />

            {areRoundsPopulated() && (
                <GameRoundInputs
                    players={players}
                    roundNumber={roundNumber}
                    currentRoundNumber={currentRoundNumber}
                    numberOfCards={GameUtils.getNumberOfCards(roundNumber, totalRounds)}
                    onGuessChange={handleGuessChange}
                    onActualChange={handleActualChange}
                    onIsValidInputChange={handleIsValidInputChange}
                />
            )}
        </div>
    );
};
